import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    withdrawals: JSON.parse(localStorage.getItem('withdrawals')) || [],
    wallets: JSON.parse(localStorage.getItem('wallets')) || [],
    investments: JSON.parse(localStorage.getItem('investments')) || [],
    tradings: JSON.parse(localStorage.getItem('tradings')) || [],
    depayTransactions: JSON.parse(localStorage.getItem('depayTransactions')) || [],

    cards: JSON.parse(localStorage.getItem('cards')) || [],
    loans: JSON.parse(localStorage.getItem('loans')) || [],
    shareholders: JSON.parse(localStorage.getItem('shareholders')) || []

}

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setWithdrawals: (state, action) =>{
            state.withdrawals = action.payload;  
            localStorage.setItem('withdrawals', JSON.stringify(state.withdrawals));
        },

        setWallets: (state, action) =>{
            state.wallets = action.payload;  
            localStorage.setItem('wallets', JSON.stringify(state.wallets));
        },

        setInvestments: (state, action) =>{
            state.investments = action.payload;  
            localStorage.setItem('investments', JSON.stringify(state.investments));
        },

        setTradings: (state, action) =>{
            state.tradings = action.payload;  
            localStorage.setItem('tradings', JSON.stringify(state.tradings));
        },

        setDepayTransactions: (state, action) =>{
            state.depayTransactions = action.payload;  
            localStorage.setItem('depayTransactions', JSON.stringify(state.depayTransactions));
        },

        setCards: (state, action) =>{
            state.cards = action.payload;  
            localStorage.setItem('cards', JSON.stringify(state.cards));
        },

        setLoans: (state, action) =>{
            state.loans = action.payload;  
            localStorage.setItem('loans', JSON.stringify(state.loans));
        },

        setShareholders: (state, action) =>{
            state.shareholders = action.payload;  
            localStorage.setItem('shareholders', JSON.stringify(state.shareholders));
        },


    }
})

export const {setWithdrawals, setCards, setLoans, setTradings, setShareholders, setWallets, setInvestments, setDepayTransactions} = historySlice.actions;
export default historySlice.reducer;


