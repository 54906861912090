import React from 'react'
import CoinMarket from '../Home/CoinMarket'
import { Typography } from '@mui/material'

const StartTrading = () => {
  return (
    <div style={{marginTop: '75px'}}>
        <Typography mb={1}  ml={3} mt={4} sx={{ fontWeight: 'bold', fontSize: 20, textTransform: 'uppercase', textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                TRADE SUPPORTED ASSETS
        </Typography>
        <Typography  sx={{textAlign: 'center'}} variant="body2" color="text.secondary">
                kindly click any asset pair to trade
        </Typography>
      <CoinMarket/>
    </div>
  )
}

export default StartTrading
