import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    coins: JSON.parse(localStorage.getItem('coins')) || {},
    coinRates: JSON.parse(localStorage.getItem('coin_rate')) || [],
    addresses: JSON.parse(localStorage.getItem('addresses')) || []
}

export const coinSlice = createSlice({
    name: 'coins',
    initialState,
    reducers: {
        setCoins: (state, action) =>{
            state.coins = action.payload;  
            localStorage.setItem('coins', JSON.stringify(state.coins));
        },
        setAddresses: (state, action) =>{
            state.addresses = action.payload;  
            localStorage.setItem('addresses', JSON.stringify(state.addresses));
        },
        setRate: (state, action) =>{
            state.coinRates = action.payload;  
            localStorage.setItem('coin_rate', JSON.stringify(state.coinRates));
        },
        
    }
})

export const {setCoins, setAddresses, setRate} = coinSlice.actions;
export default coinSlice.reducer;