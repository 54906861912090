import React from 'react'
import CoinMarket from './CoinMarket';
import WalletCards from '../Wallet/WalletCards';

import Cards from '../Cards/Cards';
import MobileMenu from './MobileMenu';
import NewCard from '../Cards/NewCard';
const Index = () => {
  return (
    <>
    <NewCard/>
    <MobileMenu/>
    <CoinMarket/>
    </>
  )
}

export default Index
