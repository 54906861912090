import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation } from "react-query";
import Slide from '@mui/material/Slide';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function ListFundings() {

    const [fundings, setfundings] = useState(null);
    const { isLoading: isLoadingfundings, refetch: getfundings } = useQuery(
        "show-fundings",
        async () => {
          return await apiClient.get("/api/show-fundings");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setfundings(res.data.fundings);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );


      

      const [fundingId, setfundingId] = useState('');
      const [fundingType, setfundingType] = useState('');
const [fundingStatus, setfundingStatus] = useState('');
const [open2, setOpen2] = React.useState(false);

const handleClose2 = () => {
      setOpen2(false);
    };
    const handleToggle2 = () => {
      setOpen2(!open2);
    };

const { isLoading: isUpdating, mutate: updatefunding } = useMutation(
      async () => {
        return await apiClient.post(`/api/update-funding`, {
          funding_id: fundingId,
          funding_status: fundingStatus,
        });
        
      },
      {
        onSuccess: (res) => {
            if (res.data.status === 'success') {
    
              localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
              setTimeout(() => {
                const event = new Event('newMessage');
                window.dispatchEvent(event);
              }, 1000);
    
              const event2 = new Event('processed');
              window.dispatchEvent(event2);                
              
            }            
        },
        onError: (err) => {
          let errorMessage = err.response?.data || err ;
          localStorage.setItem('notification', JSON.stringify({message : `${errorMessage.message}`, 'type': 'error' }));
          const event = new Event('newMessage');
          window.dispatchEvent(event);
          const event4 = new Event('processed');
          window.dispatchEvent(event4);
          
        },
      }
    );

    
      useEffect(() => {
        getfundings() 
        if (isLoadingfundings) {
            const event3 = new Event('processing');
            window.dispatchEvent(event3);
        }
      }, []);

  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        
        {isLoadingfundings? 'Loading fundings...' : 'List of Wallet fundinges'}
      </Typography>
    <TableContainer component={Paper}>
        
      {fundings !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell align="right">Amount</TableCell>
        <TableCell align="right">Gateway</TableCell>
        <TableCell align="right">Transaction ID</TableCell>
        <TableCell align="right">Status</TableCell>
          <TableCell align="right">Edit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fundings.map((funding) => (
          <TableRow
            key={funding.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            {funding.user.name}
            </TableCell>
            <TableCell align="right">{funding.user.email}</TableCell>
            <TableCell align="right">{funding.amount}</TableCell>
            <TableCell align="right">{funding.gateway}</TableCell>
            <TableCell align="right">{funding.transaction_id}</TableCell>
            <TableCell align="right">{funding.status}</TableCell>
            <TableCell align="right"><Button onClick={() =>{handleToggle2(); setfundingId(funding.id); setfundingType(funding)}} startIcon={<EditIcon />}>Edit</Button></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>

    
    <Dialog
        
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{textAlign: 'center'}}>{"UPDATE STATUS"}</DialogTitle>
        <DialogContent>
          <Box px={1} sx={{display: 'flex', justifyContent: 'space-evenly'}}>

          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="status">status</InputLabel>
                <Select
                    onChange={(e) => setfundingStatus(e.target.value)}
                    labelId="status"
                    id="status"
                    name="status"
                    label='status'
                    >
                    <MenuItem selected value=''>
                        select status
                    </MenuItem> 
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value={fundingType.status === 'processing'? 'successful' : 'approved'}>Approved</MenuItem>
                    <MenuItem value="declined">Declined</MenuItem>
                </Select>
            </FormControl>
          </Box>          
        </DialogContent>
        
        <DialogActions>
            <Button onClick={()=>updatefunding()} color='error'>{isUpdating? 'Updating...': 'Update Status'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}