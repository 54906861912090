import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentPair: JSON.parse(localStorage.getItem('currentPair')) || 'BTCUSDT',
}

export const tradingSlice = createSlice({
    name: 'currentPair',
    initialState,
    reducers: {
        setcurrentPair: (state, action) => {
            state.currentPair = action.payload;  
            localStorage.setItem('currentPair', JSON.stringify(state.currentPair));
        }       
    }
})

export const {setcurrentPair} = tradingSlice.actions;
export default tradingSlice.reducer;