import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const EditTradingPlan = () => {

    const params = useParams();
    const [plan, setPlan] = useState(null);
    const { isLoading: isLoadingplans, refetch: getplans } = useQuery(
        "edit-trading-plan",
        async () => {
          return await apiClient.get(`/api/edit-trading-plan/${params.id}`);
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setPlan(res.data.plan);
                setPlanForm({...planForm, 
                    max_amount : res.data.plan.max_amount	,
                    min_amount : res.data.plan.min_amount	,
                    min_range : res.data.plan.min_range,
                    max_range : res.data.plan.max_range,
                    final_result : res.data.plan.final_result,
                    duration : res.data.plan.duration,
                });
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      
    const dispatch = useDispatch();
    
    const initialLogin = {
        max_amount : ''	,
        min_amount : ''	,
        min_range : ''	,
        max_range : ''	,
        final_result : ''	,
        duration : '',

    }
    
    
    const [error, setErros] = useState('');
    const [planForm, setPlanForm] = useState(initialLogin);

    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postPlan } = useMutation(

        async () => {
          return await apiClient.post(`/api/update-trading-plan/${params.id}`, {
            max_amount : planForm.max_amount,	
            min_amount : planForm.min_amount,	
            min_range : planForm.min_range,	
            max_range : planForm.max_range,	
            final_result : planForm.final_result,	
            duration : planForm.duration,

          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)     
          const event2 = new Event('processed');
          window.dispatchEvent(event2);          
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
              const event2 = new Event('processed');
          window.dispatchEvent(event2);
          }    
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postPlan();
      } 

      useEffect(() => {
        getplans();
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
        {plan !== null && 

        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        EDIT TRADING PLAN
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to edit trading plans
                    </Typography>
                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="min_amount">Min Amount</InputLabel>
                            {(error !== '' && error.min_amount) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="min_amount"
                            defaultValue={plan.min_amount}
                            type={'number'}
                            name="min_amount"
                            label="Min Amount"
                            helperText={error.min_amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            defaultValue={plan.min_amount}
                            id="min_amount"
                            type={'number'}
                            name="min_amount"
                            label="Min Amount"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.min_amount}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="max_amount">Max Amount</InputLabel>
                            {(error !== '' && error.max_amount) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="max_amount"
                            defaultValue={plan.max_amount}
                            type={'number'}
                            name="max_amount"
                            label="Max Amount"
                            helperText={error.max_amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="max_amount"
                            defaultValue={plan.max_amount}
                            type={'number'}
                            name="max_amount"
                            label="Max Amount"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.max_amount}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                          <InputLabel htmlFor="min_range">Min Profit Range (%)</InputLabel>
                          {(error !== '' && error.min_range) ?

                          <OutlinedInput
                          onChange={onChange}
                          error
                          id="min_range"
                          type={'number'}
                          name="min_range"
                          label="Min Profit Range (%)"
                          defaultValue={plan.min_range}
                          helperText={error.min_range}
                          />
                          :
                          <OutlinedInput
                          onChange={onChange}
                          id="min_range"
                          type={'number'}
                          defaultValue={plan.min_range}
                          name="min_range"
                          label="Min Profit Range (%)"
                          />
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.min_range}
                          </Typography>
                      </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="max_range">Max Profit Range (%)</InputLabel>
                            {(error !== '' && error.max_range) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            defaultValue={plan.max_range}
                            id="max_range"
                            type={'number'}
                            name="max_range"
                            label="Max Profit Range (%)"
                            helperText={error.max_range}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="max_range"
                            type={'number'}
                            defaultValue={plan.max_range}
                            name="max_range"
                            label="Max Profit Range (%)"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.max_range}
                            </Typography>
                            
                        </FormControl>

                        
                    
                    <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50, marginBottom: '10px' }}>
                      <InputLabel htmlFor="final_result">Trading Model</InputLabel>
                      <Select
                          onChange={onChange}
                          labelId="final_result"
                          name="final_result"
                          id="final_result"
                          defaultValue={plan.final_result}
                          label={`Trading Model`}
                          >
                          <MenuItem selected value=''>
                          Select Model
                          </MenuItem> 
                          <MenuItem selected value='profit'>
                          Profit
                          </MenuItem> 
                          <MenuItem selected value='loss'>
                          Loss
                          </MenuItem> 
                          <MenuItem selected value='profit_loss'>
                          Profit_loss
                          </MenuItem> 

                      </Select>                        
                    </FormControl>


                        {/* <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="final_result">Trading Model</InputLabel>
                            {(error !== '' && error.final_result) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="final_result"
                            defaultValue={plan.final_result}
                            type={'text'}
                            name="final_result"
                            label="Trading Model"
                            helperText={error.final_result}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="final_result"
                            defaultValue={plan.final_result}
                            type={'text'}
                            name="final_result"
                            label="Trading Model"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.final_result}
                            </Typography>
                            
                        </FormControl> */}

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="duration">duration</InputLabel>
                            {(error !== '' && error.duration) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="duration"
                            name="duration"
                            defaultValue={plan.duration}
                            type={'text'}
                            label="duration"
                            helperText={error.duration}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="duration"
                            defaultValue={plan.duration}
                            name="duration"
                            type={'text'}
                            label="duration"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.duration}
                            </Typography>
                        </FormControl>
                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Updating...' : 'Update Plan'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }
    </div>
  )
}

export default EditTradingPlan
