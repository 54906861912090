import * as React from 'react';
import { green, pink } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import FolderIcon from '@mui/icons-material/Folder';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

export default function MobileMenu() {
  return (
    <Box sx={{paddingX: '15px'}}>
      
    <Stack mt={2} mb={4} sx={{ marginTop: '20px'}} direction="row" spacing={5} justifyContent="flex-start" alignItems="center">
      <Box component={RouterLink}  to='/send-coin' sx={{ borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar sx={{ padding: '5px', width: 56, height: 56}}>
        <ArrowOutwardIcon />
      </Avatar>  
      <span>Send</span>   
      </Box>

      <Box component={RouterLink} to='/receive' sx={{ borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar sx={{ padding: '5px', width: 56, height: 56}}>
        <CallReceivedIcon />
      </Avatar>
      <Typography>Receive</Typography> 
      </Box>

      <Box component={RouterLink} to='/trade-history' sx={{ borderRadius: '50%',  textDecoration: 'none', color: 'inherit'  }}>
      <Avatar sx={{ padding: '5px', width: 56, height: 56}}>
        <StackedBarChartIcon />
      </Avatar>
      <Typography sx={{textAlign: 'center'}}>Trades</Typography> 
      </Box>

      <Box component={RouterLink} to='/wallet' sx={{ borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar sx={{ padding: '5px', width: 56, height: 56}}>
        <AccountBalanceWalletIcon />
      </Avatar>
      <span>Wallet</span> 
      </Box>      
    </Stack>
    </Box>
  );
}