import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';



const SpotTransfer = () => {
      
  const coins = useSelector((state) => state.coins.coins);
  const coinRates = useSelector((state) => state.coins.coinRates);

  const fundings = useSelector((state) => state.wallet.fundings);
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
  const mainBal = useSelector((state) => state.wallet.withdrawableBalance);
  const refBal = useSelector((state) => state.wallet.referralBalance);

  
  const tradings = useSelector((state) => state.history.tradings);
  const investments = useSelector((state) => state.history.investments);
  const withdrawals = useSelector((state) => state.history.withdrawals);


  const calculateBalance = (coin) => {
    let amount = 0;

    fundings.forEach(fund => {
      if (fund.gateway === coin) {
        amount += parseFloat(fund.amount);
      }
      });

      tradings.forEach(trade => {
        if (trade.pair === coin) {
          amount += parseFloat(trade.profit);
        }
        });

        investments.forEach(investment => {
          if (investment.payment_source === coin && investment.status != 'declined') {
            amount -= parseFloat(investment.amount_invested);
          }
          });

          withdrawals.forEach(withdrawal => {
            
            if (withdrawal.source === coin && withdrawal.status != 'declined') {
              amount -= parseFloat(withdrawal.amount);
            }
            });
    
      return amount;
  }

    const initwallet = {
        amount : ''	,
        from_coin : ''	,
        to_coin : ''	,
        
    }
    
    
    const [error, setErros] = useState('');
    const [walletForm, setwalletForm] = useState(initwallet);

    const onChange = (e) =>
    setwalletForm({ ...walletForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postwallet } = useMutation(

        async () => {
          return await apiClient.post(`/api/spot-transfer`, {
            amount : walletForm.amount,	
            from_coin : walletForm.from_coin,
            to_coin : walletForm.to_coin,
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors) 

          const event2 = new Event('processed');
            window.dispatchEvent(event2);

          
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
          }  
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postwallet();
      } 


  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        TRANSFER FUNDS
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to transfer funds from one account to another
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2 }}>
                            <InputLabel htmlFor="amount">Amount (USD)</InputLabel>
                            {(error !== '' && error.amount) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="amount"
                            name="amount"
                            type={'number'}
                            label="Amount (USD)"
                            helperText={error.amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="amount"
                            name="amount"
                            type={'number'}
                            label="Amount (USD)"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.amount}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2 }}>
                          <InputLabel htmlFor="from_coin">From</InputLabel>
                          {(error !== '' && error.from_coin) ?
                              <Select
                                  error
                                  onChange={onChange}
                                  labelId="from_coin"
                                  id="from_coin"
                                  name="from_coin"
                                  label={`From`}
                                  helperText={error.from_coin}
                                  >
                                  <MenuItem selected value=''>
                                      Select Option
                                  </MenuItem> 
                                <MenuItem  selected value='Withdrawable Balance'>
                                Spot Wallet (bal = {formatPrice(mainBal)}) 
                                </MenuItem> 

                                <MenuItem  selected value='Referral Wallet'>
                                Referral Wallet (bal = {formatPrice(refBal)}) 
                                </MenuItem> 
                                  
                              </Select>
                          :
                              <Select
                                  onChange={onChange}
                                  labelId="from_coin"
                                  name="from_coin"
                                  id="from_coin"
                                  label={`From`}
                                  >
                                  <MenuItem selected value=''>
                                      Select Option
                                  </MenuItem> 
                                <MenuItem  selected value='Withdrawable Balance'>
                                Spot Wallet (bal = {formatPrice(mainBal)}) 
                                </MenuItem> 

                                <MenuItem  selected value='Referral Wallet'>
                                Referral Wallet (bal = {formatPrice(refBal)}) 
                                </MenuItem>  
                              </Select>
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.from_coin}
                          </Typography>
                      </FormControl> 

                      <FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2 }}>
                          <InputLabel htmlFor="to_coin">To</InputLabel>
                          {(error !== '' && error.to_coin) ?
                              <Select
                                  error
                                  onChange={onChange}
                                  labelId="to_coin"
                                  id="to_coin"
                                  name="to_coin"
                                  label={`To`}
                                  helperText={error.to_coin}
                                  >
                                  <MenuItem selected value=''>
                                      Select Coin
                                  </MenuItem> 
                                  {coinRates.length > 0 && 
                                    coinRates.map((coin) =>(
                                      <MenuItem key={coin.symbol} selected value={coin.symbol}>
                                        {coin.symbol} (bal = {formatPrice(calculateBalance(coin.symbol))}) 

                                      </MenuItem> 
                                    ))}            
                                  
                              </Select>
                          :
                              <Select
                                  onChange={onChange}
                                  labelId="to_coin"
                                  name="to_coin"
                                  id="to_coin"
                                  label={`To`}
                                  >
                                  <MenuItem selected value="">
                                      To Coin
                                  </MenuItem>   

                                  {coinRates.length > 0 && 
                                    coinRates.map((coin) =>(
                                      <MenuItem key={coin.symbol} selected value={coin.symbol}>
                                      {coin.symbol} (bal = {formatPrice(calculateBalance(coin.symbol))}) 

                                      </MenuItem> 
                                    ))} 
                              </Select>
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.to_coin}
                          </Typography>
                      </FormControl>                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'processing...' : 'Transfer Funds'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default SpotTransfer
